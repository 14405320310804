@import "../../style/variables.scss";

@import '../../style/functions.scss';

.main-screen {
  position: relative;
  overflow-x: hidden;
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100vw;

  &__footer {
    padding: vw(24px) vw(40px);
    box-sizing: border-box;
    font-family: "SF Pro Text Regular";
  font-size: vw(18px);
    line-height: vw(10px);
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    align-items: flex-end;

    @media (max-width: 768px) {
      font-size: 2.16vw;
      line-height: 3vw;
    }

    @media (max-width: 480px) {
      top: 10%;
      bottom: auto;
    }
  }

  &__headings {
    color: $my-white;
    flex: 10;

    @media (max-width: 768px) {
      flex: 3;
    }

    p {
      width: 32%;
      line-height: 1.5vw;

      @media (max-width: 768px) {
        font-size: 2.16vw;
        width: 60%;
        line-height: 3vw;
      }
    }

    @media (max-width: 480px) {
      font-size: 5.089vw;
      text-align: left;

      p {
        font-size: 4.58vw;
        line-height: 6vw;
        width: 85%;
      }
    }
  }

  &__scroll {
    display: flex;
    color: $my-white;

    @media (max-width: 768px) {
      text-align: right;
    }

    @media (max-width: 480px) {
      display: none;
    }
  }

  &__video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -999;
  }
}

.main-screen__cta-container {
  @media (min-width: 481px) {
    display: none;
  }

  @media (max-width: 480px) {
    bottom: 18%;
  }

  position: absolute;
  display: flex;
  bottom: 0;
  margin: 0 auto;
  color: $my-black;
  font-family: "SF Pro Text Regular";
  font-size: 4.6vw;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  margin-inline: auto;

  button {
    padding: 4vw;
    letter-spacing: 0.5px;
    background: $my-white;
    color: inherit;
    border-radius: 10vw;
    width: 90vw;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
}
