.modal {
  display: flex;
  height: 100vh;
  background-color: rgba(244, 243, 239, 1);
  font-family: "SF Pro Text Regular";
  flex-direction: column;
  width: 100%;
  position: fixed;
  left: 0;
  top: -100%;
  z-index: 10000;
  visibility: hidden;
  transition: 0.8s ease;

  &--visible {
    visibility: visible;
    top: 0;
  }

  @keyframes slideIn {
    from {
      top: -100%;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes slideOut {
    from {
      top: 0;
      opacity: 1;
    }
    to {
      top: -100%;
      opacity: 0;
    }
  }

  &-close {
    right: 4vw;
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    outline: inherit;
    width: 10%;

    &_cross {
      font-size: 6.042vw;
      font-weight: 700;

      @media (min-width: 481px) {
        display: none;
      }
    }

    &_text {
      @media (max-width: 480px) {
        display: none;
      }
    }

    @media (max-width: 768px) {
      font-size: 3vw;
      width: auto;
    }

    @media (max-width: 480px) {
      position: relative;
      margin: 3vw 0;
      right: 0;
      text-align: right;
    }
  }

  &-cta {
    left: 4vw;
    width: 90%;

    &_mobile {
      font-size: 3.75vw;

      @media (min-width: 481px) {
        display: none;
      }
    }

    &_text {
      @media (max-width: 480px) {
        display: none;
      }
    }

    @media (max-width: 768px) {
      margin: 0;
      width: 80%;
    }

    @media (max-width: 480px) {
      position: relative;
      left: 0;
      padding: 1vw;
      justify-content: space-between;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    padding: 2vw 4vw;
    position: relative;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      padding: 5vw;
    }

    @media (max-width: 480px) {
      padding: 0 5vw;
    }
  }

  &-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4vw;
    height: 80vh;

    @media (max-width: 480px) {
      padding: 0 4vw;
      flex-direction: column;
    }
  }

  &-contact {
    position: absolute;
    top: 0;
    font-size: 2.222vw;
    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;
      color: black;

      @media (max-width: 480px) {
        display: none;
      }
    }

    @media (max-width: 480px) {
      position: relative;
    }

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0.5vw 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      text-align: left;

      @media (max-width: 480px) {
        width: 90vw;
        font-size: 4.58vw;
        border: 1px solid rgba(0, 0, 0, 0.16);
        border-radius: 12vw;
        font-family: "SF Pro Text Regular";
        padding: 4vw 1vw;
        text-align: center;
        margin-bottom: 3vw;
      }
    }
  }

  &-addresses {
    display: flex;
    flex-direction: row;
    position: absolute;
    font-size: 1.111vw;
    bottom: 0;

    @media (max-width: 480px) {
      display: none;
    }

    p {
      width: 35%;

      @media (max-width: 768px) {
        font-size: 2vw;
        width: auto;
      }
    }
  }

  &-info {
    position: relative;
    width: 50%;

    @media (max-width: 480px) {
      width: 100%;
    }
  }
}

.modal-addresses_mobile {
  @media (min-width: 481px) {
    display: none;
  }

  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 2.5vw;
  opacity: 0.44;
  bottom: 0;

  p {
    margin: 0;
    width: 35%;
  }
}

.modal-contact_mobile {
  @media (min-width: 481px) {
    display: none;
  }

  a {
    text-decoration: none;
    color: black;
  }

  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: 4.58vw;
  text-decoration: underline;
  justify-content: space-between;
  width: 62%;
}

.modal-contact_social {
  @media (min-width: 481px) {
    display: none;
  }

  display: flex;
  flex-direction: row;

  a {
    padding-right: 2vw;
  }
}

.modal-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal__call-mobile {
  @media (min-width: 481px) {
    display: none;
  }

  padding: 4vw;
  letter-spacing: 0.5px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.16);
  color: inherit;
  border-radius: 10vw;
  width: 90vw;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
  text-align: center;
}

.modal-contact_social-link {
  width: 5vw;
  height: 5vw;
}
