@import "../../style/variables.scss";

@import "../../style/functions.scss";

form {
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 480px) {
    width: 100%;
  }

  button {
    font-family: "SF Pro Display Regular";
    font-size: 1.25vw;
    color: $my-white;
    background-color: $my-black;
    width: fit-content;
    padding: 1vw 2vw;
    border-radius: 3vw;
    margin-top: 2vw;
    border: none;
    cursor: pointer;

    span {
      margin: 0 0 0 0.5vw;
      display: inline-block;
      transform: rotate(-45deg);
      font-size: 1.3vw;

      @media (max-width: 480px) {
        font-size: 3.75vw;
      }
    }

    @media (max-width: 768px) {
      font-size: 2vw;
      padding: 1.3vw 2vw;
    }

    @media (max-width: 480px) {
      font-size: 3.75vw;
      padding: 3.333vw 6.25vw;
      border-radius: 10vw;
      position: relative;
      margin-top: 3vw;
    }
  }

  input,
  textarea {
    font-size: 1.25vw;
    padding: 2vw;
    resize: none;

    &::placeholder {
      opacity: 0.8;
    }

    outline: none;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: transparent;
    font-family: "SF Pro Text Regular";

    &:hover {
      border-bottom: 1px solid rgba(0, 0, 0, 1);
      cursor: pointer;
    }

    &:focus {
      border-bottom: 1px solid rgba(0, 0, 0, 1);
    }

    @media (max-width: 768px) {
      font-size: 2vw;
    }

    @media (max-width: 480px) {
      font-size: 3.671vw;
      padding: 5vw;
    }
  }
}

.success__hero-video {
  width: vw(128px);
  height: vw(128px);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 15.5vw;
    width: 15.5vw;
    margin-bottom: 4vw;
  }

  @media (max-width: 480px) {
    height: 22.5vw;
    width: 22.5vw;
    margin-bottom: 5vw;
  }
}

.modal__message {
  @media (min-width: 481px) {
    display: none;
  }

  font-size: 3.75vw;
  padding: 0 3.5vw;
  margin: 2vw 0;
}

.success__video-container {
  height: vw(550px);
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  @media (max-width: 768px) {
    height: 15.5vw;
    width: 15.5vw;
  }

  @media (max-width: 480px) {
    height: 22.5vw;
    width: 22.5vw;
  }

  video {
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    filter: blur(5px);
    scale: 4;
  }

  .success__video-container:after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  p {
    padding: 0;
    line-height: 1;
    color: $my-white;
    font-size: vw(80px);
    font-family: "Times New Romans";
    margin: 0;

    @media (max-width: 480px) {
      font-size: 3.671vw;
    }
  }

  .success__video-checkmark {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    text-align: center;
    position: relative;
    z-index: 10;
    width: 70%;
    margin: auto;

    img {
      width: 4.833vw;
      height: 8.125vw;

      @media (max-width: 768px) {
        width: 6.958vw;
        height: 4.835vw;
      }

      @media (max-width: 480px) {
        width: 8.958vw;
        height: 6.835vw;
      }
    }
  }
}

.error {
  font-size: 1vw;

  @media (max-width: 768px) {
    font-size: 1vw;
  }
}

.success__reach {
  font-size: vw(26px);
  letter-spacing: 0.5px;
  margin: 0;
  padding: 1vw 0 0 0;
  font-weight: 600;
  font-family: "SF Pro Text Regular";

  @media (max-width: 768px) {
    font-size: 3.071vw;
  }

  @media (max-width: 480px) {
    font-size: 3.671vw;
  }
}
.success__touch {
  letter-spacing: 0.5px;
  font-size: vw(18px);
  font-family: "SF Pro Text Regular";
  opacity: 0.44;
  margin: 0;
  padding: 1vw 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2.071vw;
  }

  @media (max-width: 480px) {
    font-size: 3.671vw;
  }
}

.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4vw 7vw 4vw;

  @media (max-width: 768px) {
    padding: 10vw 0;
  }
}
