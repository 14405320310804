@import "./variables.scss";

body {
  background-color: $my-black;
  margin: 0;
  padding: 0;
  font-family: "SF Pro Text Regular", sans-serif;
}

body,
html {
  overflow-x: hidden;
  max-width: 100%;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

.separator {
  height: 30vh;
}
