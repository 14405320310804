@import "../../style/variables.scss";

@import "../../style/functions.scss";

.header {
  display: flex;
  flex: 1;
  position: fixed;
  top: 0;
  padding: vw(10px) vw(40px);
  width: 100%;
  box-sizing: border-box;
  color: $my-white;
  font-family: "SF Pro Text Light";
  letter-spacing: 0.05vw;
  font-size: vw(18px);
  align-items: center;
  mix-blend-mode: difference;
  z-index: 1000;

  @media (max-width: 480px) {
    padding: 4vw;
  }

  @media (max-width: 768px) {
    font-size: 2.16vw;
  }

  &__logo {
    width: vw(113px);
    height: vw(32px);

    @media (max-width: 480px) {
      width: 30vw;
      height: 8.1vw;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
      width: 15vw;
      height: 4vw;
    }

    &-container {
      display: flex;
      flex: 6;

      @media (max-width: 768px) {
        flex: 1;
      }
    }
  }

  &__nav {
    flex: 4;
    display: flex;
    justify-content: space-between;

    @media (max-width: 480px) {
      display: none;
    }

    @media (max-width: 768px) {
      flex: 2;
    }

    &-menu {
      display: none;

      @media (max-width: 480px) {
        position: relative;
        display: inline-block;
        width: 11vw;
        height: 0.509vw;
        background-color: $my-white;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: $my-white;
        }

        &::before {
          top: vw(20px);
        }

        &::after {
          bottom: 0;
        }
      }
    }

    &-list {
      flex: 3;
      display: flex;
      justify-content: space-between;

      li {
        list-style: none;
      }
    }

    &-link {
      color: $my-white;
      mix-blend-mode: difference;
      align-items: center;
      text-decoration: none;
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      &-cta {
        color: $my-white;
        mix-blend-mode: difference;
        align-items: center;
        text-decoration: none;
        text-decoration: underline;
      }
    }
  }
}
