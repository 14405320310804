@import "../../style/variables.scss";

@import '../../style/functions.scss';

.open-modal__button {
  outline: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: vw(90px);
  font-family: "SF Pro Display Regular";
  padding: vw(10px) vw(25px);
  margin-right: vw(3px);
  font-size: vw(18px);
  opacity: 0.75;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  z-index: 9999;
  @media (max-width: 768px) {
    font-size: 2.4vw;
  }

  @media (max-width: 480px) {
    font-size: 4.58vw;
    padding: 3vw 4vw;
    border-radius: vw(130px);
  }

  &.light {
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: $my-white;
  }

  &.dark {
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: $my-black;
  }

  span {
    display: inline-block;
    transform: rotate(-45deg);
    padding-left: vw(3px);
    padding-bottom: vw(2px);
    font-size: vw(14px);
    text-decoration: none;
    vertical-align: middle;

    @media (max-width: 768px) {
      font-size: 2.4vw;
    }

    @media (max-width: 480px) {
      font-size: 4.58vw;
    }
  }
}
