@import "../../style/variables.scss";

@import '../../style/functions.scss';

.footer {
  display: flex;
  flex-direction: column;
  color: $my-white;
  padding: vw(40px);
  font-family: "SF Pro Text Regular";

  @media (max-width: 480px) {
    height: auto;
  }

  hr {
    width: 100%;
    opacity: 0.16;
    margin: vw(10px) 0;
  }

  &__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 480px) {
      flex-direction: column;
      padding: 5vw 0 10vw 0;
    }
  }

  &__address {
    display: flex;
    justify-content: flex-start;
    padding-bottom: vw(20px);
    font-size: vw(24px);
    font-style: normal;

    @media (max-width: 768px) {
      font-size: 2.1vw;
    }

    @media (max-width: 480px) {
      font-size: 4.58vw;
      width: 70%;

      p {
        margin: 2vw 0;
      }
    }
  }

  &__contact {
    display: flex;
    text-align: right;

    a {
      padding: 1.5vw 2.9vw;
      font-size: vw(48px);
      opacity: 1;
      @media (max-width: 480px) {
        font-size: 7.125vw;
        padding: 4vw 3vw;
        width: 90vw;
        text-align: center;
      }
    }

    span {
      font-size: vw(28px);
      opacity: 1;

      @media (max-width: 480px) {
        font-size: 6.125vw;
      }
    }
  }

  &__vision {
    flex: 0 0 35%;
    font-size: vw(48px);
    display: flex;
    align-items: center;
    letter-spacing: 0.08vw;

    @media (max-width: 480px) {
      font-size: 7.125vw;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    margin-top: 3vw;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  &__links {
    display: flex;
    flex-direction: row;
    font-size: vw(18px);
    justify-content: space-between;
    flex: 1;
    font-family: "SF Pro Display Light";
    letter-spacing: 0.08vw;

    @media (max-width: 768px) {
      font-size: 2vw;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      font-size: 4.58vw;
    }

    &-sitemap,
    &-socials,
    &-legal {
      margin-bottom: vw(16px);

      ul {
        list-style: none;
        padding: vw(10px) 0;
      }

      li {
        margin-bottom: vw(8px);
      }

      a {
        color: white;
        line-height: vw(24px);
        opacity: 0.5;
        text-decoration: none;
      }
    }
  }

  &__copyright {
    position: relative;
    width: 80%;
    right: -3%;
    bottom: 5%;

    @media (max-width: 480px) {
      width: 100%;
      right: 0;
    }

    video {
      position: absolute;
      bottom: 0;
      right: -5%;
      max-width: 70vw;
      max-height: 50vw;
      object-fit: contain;
      filter: blur(10px);
      backdrop-filter: blur(10px);
      z-index: -1;
      transform: rotateY(180deg);
      transform: rotateX(180deg);

      @media (max-width: 480px) {
        margin-top: -70%;
        width: 100vw;
        right: 0;
        max-width: 100vw;
        max-height: 100vh;
        height: 60vh;
        object-fit: cover;
        position: relative;
        z-index: -999;
      }
    }

    p {
      position: absolute;
      padding: vw(20px);
      bottom: 0;
      right: 0;
      margin: 0;
      color: $my-white;
      font-size: vw(18px);
      z-index: 1;
      text-align: center;
      width: 30%;
      filter: blur(0px);
      font-family: "SF Pro Display Light";
      letter-spacing: 0.08vw;

      @media (max-width: 768px) {
        width: 50%;
        font-size: 2vw;
      }

      @media (max-width: 480px) {
        font-size: 4.58vw;
        width: 100%;
        text-align: left;
      }
    }
  }
}

.footer__address-container {
  display: flex;
  width: 150%;
  flex-direction: row;

  @media (max-width: 768px) {
    width: 200%; 
  }

  @media (max-width: 480px) {
    flex-direction: column;
    width: 90%;
    margin-top: 6vw;
  }
}
